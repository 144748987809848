import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import {
  jssPreset,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
//import CookiesNotification from 'src/components/CookiesNotification';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
//import SettingsNotification from 'src/components/SettingsNotification';
import InternetConnection from 'src/components/InternetConnection';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import { InstitutionProvider } from '../src/contexts/InstitutionContext';
import { SetupProvider } from '../src/contexts/SetupContext';
import { UserProvider } from '../src/contexts/UserContext';
import { ApplicationProvider } from '../src/contexts/ApplicationContext';
import { AdminProvider } from '../src/contexts/AdminContext';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import routes, { renderRoutes } from 'src/routes';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();
console.log(navigator.onLine)
const App = () => {
  const { settings } = useSettings();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

 

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider
            dense={false}
            maxSnack={3}
          >
            <Router history={history}>
          
              <AuthProvider>
              <InstitutionProvider>
                <SetupProvider>
                  <UserProvider>
                    <ApplicationProvider>
                      <AdminProvider>
                <GlobalStyles />
                <ScrollReset />
                <GoogleAnalytics />
                
                <InternetConnection />
                {renderRoutes(routes)}
                </AdminProvider>
                </ApplicationProvider>
                </UserProvider>
                </SetupProvider>
                </InstitutionProvider>
              </AuthProvider>
              
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
