import React, { createContext, useEffect, useReducer } from 'react';
import SplashScreen from 'src/components/SplashScreen';
import Cookies from 'js-cookie';
import AWS from 'aws-sdk';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
require('dotenv').config();

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  summary : null,
  image: null
};

const isValidToken = accessToken => {
  if (!accessToken) {
    return false;
  }

  //const decoded = jwtDecode(accessToken);
  //const currentTime = Date.now() / 1000;

  return true;
};

const setSession = (accessToken, refreshToken, idToken) => {
  if (accessToken) {
    Cookies.set('accessToken', accessToken);
    //localStorage.setItem('idToken',idToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axios.defaults.headers.common.refreshToken = refreshToken;
    axios.defaults.headers.common.idToken = idToken;
  } else {
    Cookies.remove('accessToken');
    Cookies.remove('rfsrt');
    Cookies.remove('idToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user, institution,image } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
        institution,
        image
      };
    }
    case 'LOGIN': {
      const { user, message, image ,institution } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
        institution,
        message,
        image
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        image: null
      };
    }
    case 'ERROR': {
      const { err } = action.payload;
      return {
        ...state,
        isAuthenticated: false,
        err
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'FORGOT_PASSWORD': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        image: null
      };
    }
    case 'CONFIRM_FORGOT_PASSWORD': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        image: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  reset_password: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  forgot_password: () => Promise.resolve(),
  confirm_forgot_password: () => Promise.resolve()
});

export const AuthProvider = ({ children }) => {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  let bucketName = 'erpinstitution';
  const s3 = new AWS.S3();

  const arrayBufferToBase64 = buffer => {
    var binary = '';
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach(b => (binary += String.fromCharCode(b)));
    return window.btoa(binary);
  };

  const login = async (user_email, password) => {
    console.log(user_email)
    try {
      // const user = await Auth.signIn(email,password);
      // console.log(user);
      // const token = user.storage.accessToken;
      // const decoded = jwt.verify(token);
      // console.log(decoded);

      let response;

      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://jwa0stlj67.execute-api.ap-south-1.amazonaws.com/test/signin',
          { 
            user_email,
            password,
            app_name : "company",
           }
        );
      } else {
        response = await axios.post(`https://jwa0stlj67.execute-api.ap-south-1.amazonaws.com/prod/signin`, {
          user_email,
          password,
          app_name : "company",
      
        });
      }

      console.log(response, 'from resp');
      if (response.data.session_key) {
        window.localStorage.setItem('session', response.data.session_key);
        window.localStorage.setItem('username', user_email);
        dispatch({
          type: 'LOGIN',
          payload: {
            message: response.data.message
          }
        });
      }

      setSession(
        response.data.access_token,
        response.data.refresh_token,
        response.data.id_token
      );
      Cookies.set('rfsrt', response.data.refresh_token);
      Cookies.set('idToken', response.data.id_token);
      console.log(response.data.payload.institution)
      var img = await s3
        .getObject({
          Bucket: bucketName,
          Key: response.data.payload.institution_logo
        })
        .promise()
        .then(res => {
          if (res) {
            return res;
          } else {
            return null;
          }
        })
        .catch(err => {
          console.log(err);
        });
      if (img === undefined) {
        dispatch({
          type: 'LOGIN',
          payload: {
            user: response.data.payload,
            institution: response.data.payload.institution,
            message: response.data.message,
            image: null,
            isAuthenticated : true
          }
        });
      } else {
        var base64Flag = `data:${img.contentType};base64,`;
        var imgData = arrayBufferToBase64(img.Body);

        dispatch({
          type: 'LOGIN',
          payload: {
            user: response.data.payload,
            institution: response.data.payload.institution,
            message: response.data.message,
            image: base64Flag + imgData,
            isAuthenticated : true
          }
        });
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        dispatch({
          type: 'ERROR',
          payload: {
            err: err.response.data.message
          }
        });
      } else {
        dispatch({
          type: 'ERROR',
          payload: {
            err: 'Network Error'
          }
        });
      }
    }
  };
  const reset_password = async password => {
    try {
      let response;
      const session = window.localStorage.getItem('session');
      const userName = window.localStorage.getItem('username');
      const payload = {
        session_key: session,
        user_email: userName,
        password: password,
        app_name: "company"
      };

      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://jwa0stlj67.execute-api.ap-south-1.amazonaws.com/test/newPassword',
          payload
        );
      } else {
        response = await axios.post(
          'https://jwa0stlj67.execute-api.ap-south-1.amazonaws.com/prod/newPassword',
          payload
        );
      }
      window.localStorage.removeItem('session');
      window.localStorage.removeItem('username');
      dispatch({
        type: 'NEW_PASSWORD',
        payload: {
          user: response.data.payload,
          message: response
        }
      });
    } catch (err) {
      console.log(err.response);
    }
  };

  const forgot_password = async user_email => {
    try {
      let response;
      console.log(user_email)
      const payload = {
        user_email: user_email,
        app_name: "company"
      };

      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://jwa0stlj67.execute-api.ap-south-1.amazonaws.com/test/forgotPassword',
          payload
        );
      } else {
        response = await axios.post(
          'https://jwa0stlj67.execute-api.ap-south-1.amazonaws.com/prod/forgotPassword',
          payload
        )
        console.log(response)
      }
      history.push('/confirm')
      window.localStorage.setItem('user_email',user_email);
      dispatch({
        type: 'FORGOT_PASSWORD',
        payload: {
          user: response.data.payload,
        }
      });
    } catch (err) {
      console.log(err.response);
    }
  };

  const confirm_forgot_password = async (user_email,code,password) => {
    try {
      let response;
      const payload = {
        user_email: user_email,
        code: code,
        password: password,
        app_name: "company"
      };

      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://jwa0stlj67.execute-api.ap-south-1.amazonaws.com/test/confirmForgotPassword',
          payload
        );
      } else {
        response = await axios.post(
          'https://jwa0stlj67.execute-api.ap-south-1.amazonaws.com/prod/confirmForgotPassword',
          payload
        )
        console.log(response)
      }
      dispatch({
        type: 'CONFIRM_FORGOT_PASSWORD',
        payload: {
          user: response.data.payload,
        }
      });
    } catch (err) {
      console.log(err.response);
      dispatch({
          type: 'ERROR',
          payload: {
            err: err.response.data.message
          }
        });
    }
  };

  const logout = async () => {
    if (process.env.REACT_APP_ENV === 'test') {
      await axios
        .post('https://jwa0stlj67.execute-api.ap-south-1.amazonaws.com/test/logout')
        .then(res => {
          setSession(null);
        })
        .catch(err => {
          setSession(null);
        });
    } else {
      await axios
        .post('https://jwa0stlj67.execute-api.ap-south-1.amazonaws.com/prod/logout')
        .then(res => {
          setSession(null);
        })
        .catch(err => {
          setSession(null);
        });
    }

    dispatch({ type: 'LOGOUT' });
  };

  const register = async (email, name, password) => {
    const response = await axios.post('/api/account/register', {
      email,
      name,
      password
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = Cookies.get('accessToken');
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          const options = {
            headers: { accessToken }
          };
          let response;

          if (process.env.REACT_APP_ENV === 'test') {
            response = await axios.get(
              'https://jwa0stlj67.execute-api.ap-south-1.amazonaws.com/test/continuity'
            );
          } else {
            response = await axios.get(
              'https://jwa0stlj67.execute-api.ap-south-1.amazonaws.com/prod/continuity'
            );
          }
          console.log(response)
          const user = response.data.payload;
          console.log(user);
          //const institution = response.data.payload.institution;
          //console.log(institution);

          var img = await s3
        .getObject({
          Bucket: bucketName,
          Key: response.data.payload.institution_logo
        })
        .promise()
        .then(res => {
          if (res) {
            return res;
          } else {
            return null;
          }
        })
        .catch(err => {
          console.log(err);
        });
        var imgData = null;
        var base64Flag = null;
        if(img !== undefined){
        base64Flag = `data:${img.contentType};base64,`;
        imgData = arrayBufferToBase64(img.Body);
        }

            dispatch({
              type: 'INITIALISE',
              payload: {
                isAuthenticated: true,
                user: user,
                institution : '',
                image: base64Flag + imgData
              }
            });
          
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null,
              institution : null
            }
          });
        }
      } catch (err) {
        console.error(err.response);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null,
            institution : null
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
        reset_password,
        forgot_password,
        confirm_forgot_password
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
