import React, { createContext, useReducer } from 'react';
import gql from 'graphql-tag';
import client from '../utils/GQLClient';
//import getinstitutionquery from '../views/Institution/getinstitutionquery'

const initialCreateState = {
  isCreated: false,
  isInitialised: false,
  user: null,
  users: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isCreated, user } = action.payload;

      return {
        ...state,
        isCreated,
        isInitialised: true,
        user
      };
    }

    case 'UPDATE_INSTITUTION': {
      const { user ,updatesuccessfull } = action.payload;

      return {
        ...state,
        updatesuccessfull,
        user
      };
    }
    case 'UPDATE_INSTITUTION_ERR': {
      const { err } = action.payload;

      return {
        ...state,
        updatesuccessfull: false,
        err
      };
    }

    default: {
      return { ...state };
    }
  }
};

// const getinstitutionquery = gql`
// query get_institution {
//   get_institution {
//     _id
//       user_id
//       institution_name
//       first_name
//       last_name 
//       owner_email
//       owner_contact
//       address {
//         street
//           city
//           state
//           postal_code
//           country
//       }
//       contact{
//         phone
//           mobile 
//           fax
//           website
//           secondary_email
//       }
//   }
// }
// `;

const ApplicationContext = createContext({
  ...initialCreateState,
  updateuserrole: () => Promise.resolve(),

});

export const ApplicationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialCreateState);

  const updateuserrole = async (          
    _id ,
    user_email,
    app_name,
    app_role        ) => {
        console.log(_id,user_email,app_name,app_role)
    try {
      const ADD = gql`
        mutation update_app_role(
          $_id                   : String!
          $user_email            : String!
          $app_name              : String!
          $app_role              : String!

        ) {
            update_app_role(
                _id                : $_id          
                user_email         : $user_email
                app_name           : $app_name
                app_role           : $app_role        
         
          ) {
            _id
          }
        }
       
      `;
      const { data } = await client('adminapi')
        .mutate({
          mutation: ADD,
          
          variables: {
            _id                :_id          ,
            user_email :user_email,
            app_name  : app_name,
            app_role               :app_role         ,
          },
          
     
       
     
        })
        .then(res => {
          console.log(res)
          return res;
        });

      console.log(data.update_user_role);

      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: data.update_user_role,
          updatesuccessfull : true
        }
      });
    } catch (err) {
      dispatch({
        type: 'UPDATE_INSTITUTION_ERR',
        payload: {
          err: err
        }
      });
      console.log(err);
    }
  };

  

  

  return (
    <ApplicationContext.Provider
      value={{
        ...state,
        updateuserrole,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export default ApplicationContext;