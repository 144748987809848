/* eslint-disable no-use-before-define */
import React from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import Logo from 'src/components/Logo';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import useAuth from '../../../hooks/useAuth';
import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from 'clsx';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import MenuIcon from '@material-ui/icons/Menu';
import { Link as RouterLink } from 'react-router-dom';
import Contacts from './Contacts';
import {

  Box,
  Divider,
  Drawer,
  Chip,
  Hidden,
  AppBar,
  Toolbar,
  IconButton,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import Account from './Account';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import Search from './Search';
import Settings from './Settings';
import {
  Edit as EditIcon,
  BarChart as BarChartIcon,
  PieChart as PieChartIcon
} from 'react-feather';
import NavItem from './NavItem';
import Label from 'src/components/Label';
import Notifications from './Notifications';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import SpeedIcon from '@material-ui/icons/Speed';
import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined';
import PeopleIcon from '@material-ui/icons/People';

const Users = {
  subheader: 'Company Panel',
  items: [
    {
      title: 'Dashboard',
      href : '/app/admin/summary',
      icon: HomeOutlinedIcon,
     
    },
    {
      title: 'Tenants',
      href : '/app/admin/tenants',
      icon: PeopleIcon,
     
    },
    {
      title: 'Settings',
      href : '/app/admin/settings',
      icon: SettingsOutlinedIcon,    
    },
  ]
};


function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  action: {
    marginBottom: theme.spacing(1)
  }
}));

const sections = [
  {
    items: [
      {
        icon: PieChartIcon,
        href: <Search />
      },
      {
        icon: BarChartIcon,
        href: <Search />
      }
    ]
  }
];

function renderRightNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceRightChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceRightChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderRightNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useRightStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  desktopDrawer: {
    width: 70,
    top: 64,
    height: 'calc(100% - 64px)'
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  action: {
    marginBottom: theme.spacing(1)
  }
}));

const useLogoStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  desktopDrawer: {
    width: 70,
    top: 64,
    height: 'calc(100% - 64px)'
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  menuButton: {
    marginRight: theme.spacing(2)
  },


  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 70
  },
  action: {
    marginBottom: theme.spacing(1)
  }
}));

const TopBar = ({ className, open, setOpen, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const rightClasses = useRightStyles();
  const logoClasses = useLogoStyles();
  const location = useLocation();
  const { user } = useAuth();
  console.log(open);
  let users = '';


  
  
  const handleDrawer = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
 <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
            <List
              key={Users.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {Users.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: Users.items,
                pathname: location.pathname
              })}
            </List>
       
        </Box>
        <Divider />
        </PerfectScrollbar>

        <Box flexGrow={1} />
       
        <Divider />
        <Box p={2} flexDirection="column" justifyContent="flex-end" >
          <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
             
  
            <Typography variant="h6" color="textPrimary">
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              // component={RouterLink}
              // to="/docs"
              className={classes.action}
            >
              Check our docs
            </Link>
            </Box>
       
        </Box>

    </Box>
  );

  const Rightcontent = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          {sections.map(section => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky >
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderRightNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  const Logocontent = (
    <Box height="100%" display="flex" flexDirection="column" >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
       
        <Divider />
     
        <Box p={2}>
        </Box>
        <Divider />
        <Box p={2} flexDirection="column" justifyContent="flex-end">
          <Box flexGrow={1} />
          <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
            <Typography variant="h6" color="textPrimary">
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              // component={RouterLink}
              // to="/docs"
              className={classes.action}
            >
              Check our docs
            </Link>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );


  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar className={clsx(classes.root, className)} {...rest}>
        <Toolbar className={classes.toolbar}>
          {open === true && (
            <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawer}
            edge="start"
            className={clsx(classes.menuButton, open)}
            >
            <MenuIcon fontSize = "large" />
            </IconButton>
          )}
         {open === false && (
            <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawer}
            edge="start"
            className={clsx(classes.menuButton, open)}
            >
            <MenuOpenIcon fontSize = "large" />
            </IconButton>
          )}
          <Hidden mdDown>
          <RouterLink to="/">
            {/* <Logo /> */}
          </RouterLink>
        </Hidden>
         
          <Box ml={2} flexGrow={1} />
          {/* <Search /> */}
          {/* <Contacts /> */}
          <Notifications />
          <Settings />
          <Box ml={2}>
            <Account />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        classes={{ paper: classes.desktopDrawer }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        {content}
      </Drawer>
     {/* {open === false && (
        <Hidden mdDown>
       <Drawer
       classes={{ paper: logoClasses.desktopDrawer }}
       variant="persistent"
       anchor="left"
       open={true}
     >
       {Logocontent}
     </Drawer>
     </Hidden>
     )} */}
      {/* <Drawer
        classes={{ paper: rightClasses.desktopDrawer }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        {Rightcontent}
      </Drawer> */}
    </div>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;
