import React, { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  InputAdornment,
  IconButton,
  Link,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  Divider,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  colors,
  makeStyles,
  TableContainer ,
  Dialog,
  MenuItem,

  withStyles
} from '@material-ui/core';
import { useHistory } from "react-router-dom";
import {
    PlusCircle as PlusCircleIcon,
    MoreVertical as MoreIcon,
    ArrowRight as ArrowRightIcon,
    Search as SearchIcon,
    MousePointer
} from 'react-feather';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import Label from 'src/components/Label';
import getInitials from 'src/utils/getInitials';
import GenericMoreButton from 'src/components/GenericMoreButton';
import getName from 'src/utils/getName';
import gql from 'graphql-tag';
import client from '../../utils/GQLClient';
import useIsMountedRef from 'src/hooks/useIsMountedRef';

import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import NotInterestedOutlinedIcon from '@material-ui/icons/NotInterestedOutlined';


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.background.dark,
    
  },

}))(TableCell);

const StyledTableCell1 = withStyles((theme) => ({
  stickyHeader :{
    stickyHeader : true
  }

}))(TableCell);

const StyledTextField = withStyles((theme) => ({
  root: {
    width: '50%',
    "& .MuiInputBase-root": {
      height: 45
    }
  }
}))(TextField);

const statusOptions = [
  {
    id: 'all',
    name: 'All Users'
  },
  {
    id: 'active',
    name: 'Active Users'
  },
  {
    id: 'inactive',
    name: 'In-Active Users'
  },

];

const sortOptions = [
  {
    value: 'createdAt|desc',
    label: 'Newest first'
  },
  {
    value: 'createdAt|asc',
    label: 'Oldest first'
  }
];

const getStatusLabel = (invoiceStatus) => {
  const map = {
    inactive: {
      text: 'DEACTIVATED',
      color: 'error'
    },
    active: {
      text: 'ACTIVE',
      color: 'success'
    },
    pending: {
      text: 'Pending',
      color: 'warning'
    }
  };

  const { text, color } = map[invoiceStatus];

  return (
    <Label color={color}>
      {text}
    </Label>
  );
};

const applyFilters = (tenants, query, filters) => {
  return tenants.filter((invoice) => {
    let matches = true;

    if (query) {
      const properties = [ 'institution_name','institution_id'];
      let containsQuery = false;

      properties.forEach((property) => {
        if (invoice[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    if (filters.status && invoice.status !== filters.status) {
      matches = false;
    }

    return matches;
  });
};

const applyPagination = (users, page, limit) => {
  return users.slice(page * limit, page * limit + limit);
};

const useStyles = makeStyles((theme) => ({
  root: {},
  queryField: {
    flexBasis: 300
  },
  statusField: {
    flexBasis: 200
  },
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
  avatar: {
    backgroundColor: colors.red[500],
    color: colors.common.white
  },
  sticky: {
    position: "sticky",
    right: 0,
  },
  sticky1: {
    position: "sticky",
    right: 0,
    background: "white",
    
  },
  imageCell: {
    fontSize: 0,
    width: 68,
    flexBasis: 68,
    flexGrow: 0,
    flexShrink: 0
  },
}));

const noPointer = {cursor: 'pointer'};
 

const UsersList = ({ className, tenants, ...rest }) => {
  const classes = useStyles();
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState('');
  const [sort, setSort] = useState(sortOptions[0].value);
  const [filters, setFilters] = useState({
    status: null
  });
  const isMountedRef = useIsMountedRef();
  const history = useHistory();

  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
  };

  const handleStatusChange = (event) => {
    event.persist();

    let value = null;

    if (event.target.value !== 'all') {
      value = event.target.value;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value
    }));
  };

  const handleSortChange = (event) => {
    event.persist();
    setSort(event.target.value);
  };

  const handleSelectAllInvoices = (event) => {
    setSelectedInvoices(event.target.checked
      ? tenants.map((invoice) => invoice._id)
      : []);
  };

  const handleSelectOneInvoice = (event, invoiceId) => {
    if (!selectedInvoices.includes(invoiceId)) {
      setSelectedInvoices((prevSelected) => [...prevSelected, invoiceId]);
    } else {
      setSelectedInvoices((prevSelected) => prevSelected.filter((id) => id !== invoiceId));
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
  };




  const [open, setOpen] = useState(false);
const handleClickOpen = () => {
  setOpen(true);
};
const handleClose = () => {
  setOpen(false);
};

const [id , setId] = useState({})
  const [open1, setOpen1] = useState(false);
  const handleAssignAppOpen = async (event , id) => {
    console.log(id)
    setId(id)
    setOpen1(true);
  }; 
  const handleAssignAppClose = () => {
    setOpen1(false);
  };


  const [editid , setEditId] = useState([])
  const [editopen, setEditOpen] = useState(false);
  const handleClickEditOpen = async (event , id,first_name,last_name) => {
    console.log(id)
    setEditId({
      id : id ,
      first_name,
      last_name,
    })
    setEditOpen(true);
  }; 
  const handleClickEditClose = () => {
    setEditOpen(false);
  };

  const [deleteId , setDeleteId] = useState({})
  const [deleteopen, setDeleteOpen] = useState(false);
  const handleClickDeleteOpen =  async (event , id,first_name,last_name,status) => {
    setDeleteId({
      id : id,
      first_name,
      last_name,
      status
    })
    setDeleteOpen(true);
  };
  const handleClickDeleteClose = () => {
    setDeleteOpen(false);
  };


const [appname , setAppName] = useState()
  // Usually query is done on backend with indexing solutions
  const filteredInvoices = applyFilters(tenants, query, filters);
  const paginatedInvoices = applyPagination(filteredInvoices, page, limit);
  const enableBulkOperations = selectedInvoices.length > 0;
  const selectedSomeInvoices = selectedInvoices.length > 0 && selectedInvoices.length < tenants.length;
  const selectedAllInvoices = selectedInvoices.length === tenants.length;

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box p={2}>
      <Box
          display="flex"
          alignItems="center"
        >
          <StyledTextField
            //className={classes.queryField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon
                    fontSize="small"
                    color="action"
                  >
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              )
            }}
            onChange={handleQueryChange}
            placeholder="Search By Tenant Name or Tenant ID"
            value={query}
            variant="outlined"
          />
     
          
          <Box flexGrow={1} />
          <StyledTextField
            className={classes.statusField}
            label="Status"
            name="status"
            onChange={handleStatusChange}
            select
            SelectProps={{ native: true }}
            value={filters.status || 'all'}
            variant="outlined"
          >
            {statusOptions.map((statusOption) => (
              <option
                key={statusOption.id}
                value={statusOption.id}
              >
                {statusOption.name}
              </option>
            ))}
          </StyledTextField>   
        </Box>
 
      </Box>
      {enableBulkOperations && (
        <div className={classes.bulkOperations}>
          <div className={classes.bulkActions}>
            <Checkbox
              checked={selectedAllInvoices}
              indeterminate={selectedSomeInvoices}
              onChange={handleSelectAllInvoices}
            />
            <Button
              variant="outlined"
              className={classes.bulkAction}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              className={classes.bulkAction}
            >
              Edit
            </Button>
          </div>
        </div>
      )}
      <Divider />
      <PerfectScrollbar>
     
        <TableContainer  >
          <Table >
            <TableHead>
              <TableRow>
                <StyledTableCell padding="checkbox"  > 
                  <Checkbox
                    checked={selectedAllInvoices}
                    indeterminate={selectedSomeInvoices}
                    onChange={handleSelectAllInvoices}
                  />
                </StyledTableCell>
                <StyledTableCell >

                </StyledTableCell>
                <StyledTableCell >
                  TENANT NAME / ID
                </StyledTableCell>
                <StyledTableCell>
                  OWNER NAME / ID
                </StyledTableCell>
                <StyledTableCell>
                  OWNER CONTACT
                </StyledTableCell>
                <StyledTableCell >
                  NO OF APPS
                </StyledTableCell>
                <StyledTableCell >
                  STATUS
                </StyledTableCell>
                <StyledTableCell >
                  ACTIONS
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedInvoices.map((invoice) => {
                const isInvoiceSelected = selectedInvoices.includes(invoice._id);

                return (
                  <TableRow
                  style={noPointer}
                    hover
                    key={invoice._id}
                    selected={isInvoiceSelected}
                   
                  >
                    <TableCell padding="checkbox" 
                    
                    >
                      <Checkbox
                        checked={isInvoiceSelected}
                        onChange={(event) => handleSelectOneInvoice(event, invoice._id)}
                        value={isInvoiceSelected}
                      />
                    </TableCell>
                    <TableCell className={classes.imageCell}  
                    onClick={() => history.push(`/app/admin/tenant/${invoice.institution_id}`)}>
                      <Avatar className={classes.avatar}>
                        {getInitials(`${invoice.institution_name} `)}
                      </Avatar>
                    </TableCell>
                    <TableCell
                     onClick={() => history.push(`/app/admin/tenant/${invoice.institution_id}`)}>
                     <Typography
                        color="inherit"
                        variant="h6"
                      >
                        {getName(`${invoice.institution_name}`)}
                      </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {invoice.institution_id}
                    </Typography>
                    </TableCell>
                    <TableCell
                     onClick={() => history.push(`/app/admin/tenant/${invoice.institution_id}`)}
                    >  
                    <Typography
                        color="inherit"
                        variant="h6"
                      >
                        {getName(`${invoice.first_name}  ${invoice.last_name}`)}
                      </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {invoice.owner_id}
                    </Typography>
                    </TableCell>
                    <TableCell
                     onClick={() => history.push(`/app/admin/tenant/${invoice.institution_id}`)}
                    >  
                    <Typography
                        color="inherit"
                        variant="h6"
                      >
                       {invoice.owner_email}  
                      </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {invoice.owner_contact}
                    </Typography>         
                    </TableCell>
                    <TableCell  onClick={() => history.push(`/app/admin/tenant/${invoice.institution_id}`)} >    
                   {invoice.apps_count}
                    </TableCell>
                    <TableCell  onClick={() => history.push(`/app/admin/tenant/${invoice.institution_id}`)}>  
                    <Typography variant="body2" color="textSecondary">
                    {getStatusLabel(`${invoice.status}`)} 
                    </Typography>   
                    </TableCell>
                    <TableCell  onClick={() => history.push(`/app/admin/tenant/${invoice.institution_id}`)}>  
                    <IconButton 
                            component={RouterLink}
                              to={{ 
                                pathname:`/app/admin/tenant/${invoice.institution_id}`,
                                //value: employee._id
                              }}
                            >
                              <SvgIcon fontSize="small">
                                <ArrowRightIcon />
                              </SvgIcon>
                    </IconButton>  
                    </TableCell>
                  
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          </TableContainer>
       
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={filteredInvoices.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
        
    </Card>
  );
};

UsersList.propTypes = {
  className: PropTypes.string,
  invoices: PropTypes.array.isRequired
};

UsersList.defaultProps = {
  invoices: []
};

export default UsersList;