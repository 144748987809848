import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  Hidden,
  Typography,
  makeStyles
} from '@material-ui/core';
import { APP_VERSION } from 'src/constants';
require('dotenv').config();

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default
  },
  toolbar: {
    height: 64
  },
  logo: {
    marginRight: theme.spacing(2)
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}));

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      color="default"
      {...rest}
    >{console.log(process.env.REACT_APP_ENV, process.env)}
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
        
        </RouterLink>
        <Hidden mdDown>
          <Typography
            variant="caption"
            color="textSecondary"
          >
            Release Version
            {' '}
            {APP_VERSION}  {process.env.REACT_APP_ENV}
          </Typography>
        </Hidden>
        <Box flexGrow={1} />
       
        <Button
          color="secondary"
          component={RouterLink}
          to="/app/admin/institution"
          variant="contained"
          size="small"
        >
          LOG IN
        </Button>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
