import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/Guards/AuthGuard';
import GuestGuard from 'src/components/Guards/GuestGuard';
import HomeView from 'src/views/home/HomeView';
import AllApps from 'src/views/Tenants/AllTenants'


export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    path: '/accessdenied',
    component: lazy(() => import('src/views/errors/AccessDenied'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/reset_password',
    component: lazy(() => import('src/views/auth/LoginView/Reset_Password'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forgot',
    component: lazy(() => import('src/views/auth/LoginView/Forgot_Password'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/confirm',
    component: lazy(() => import('src/views/auth/LoginView/Confirm_forgot_password'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
    
      {
        exact: true,
        path: '/app/admin/institution',
        component: lazy(() => import('src/views/Institution'))
      },
      {
        exact: true,
        path: '/app/admin/summary',
        component: lazy(() => import('src/views/Summary'))
      }, 
      {
        exact: true,
        path: '/app/admin/dashboard',
        component: lazy(() => import('src/views/Dashboard'))
      },
      {
        exact: true,
        path: '/app/admin/onboarding',
        component: lazy(() => import('src/views/Onboarding'))
      },
      {
        exact: true,
        path: '/app/admin/tenants',
        component: lazy(() => import('src/views/Tenants'))
      },
      {
        exact: true,
        path: '/app/admin/tenant/:id',
        component: lazy(() => import('src/views/TenantDetailView'))
      },
      {
        exact: true,
        path: '/app/admin/tenant/:id/edit',
        component: lazy(() => import('src/views/TenantEditView'))
      },
      {
        exact: true,
        path: '/app/admin/addTenant',
        component: lazy(() => import('src/views/AddTenant'))
      },
      {
        exact: true,
        path: '/app/admin/users',
        component: lazy(() => import('src/views/Users'))
      },
      {
        exact: true,
        path: '/app/admin/onboarding/list',
        component: lazy(() => import('src/views/Onboarding/List'))
      },
      {
        exact: true,
        path: '/app/admin/onboarding/header',
        component: lazy(() => import('src/views/Onboarding/Header'))
      },
      {
        exact: true,
        path: '/app/admin/settings',
        component: lazy(() => import('src/views/Settings'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  // {
  //   path: '/app',
  //   guard: AuthGuard,
  //   layout: AllApps,
  //   routes: [
  //     {
  //       exact: true,
  //       path: '/app/admin/applications/detail',

  //       component: lazy(() => import('src/views/Applications/AppDetails'))
  //     },
  //   ]
  // },

  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: HomeView
      },

      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
