import React, {
  useRef,
  useState
} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Drawer,
  Typography,
  IconButton,
  Tooltip,
  Card,
  Divider,
  Button,
  makeStyles
} from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import getInitials from 'src/utils/getInitials';
import getName from 'src/utils/getName';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  },
  wrapper: {
  
  },
  contentContainer: {
    flex: '1 1 auto',
    overflow: 'auto'
  },
  wrapper1: {
    paddingTop: 0
  },
  drawer: {
    width: '33%',
    maxWidth: '100%',
  
  },
  avatar1: {
    height: 60,
    width: 60
  },
  name: {
    marginTop: theme.spacing(2)
  },
  listbox : {
    backgroundColor: "#e0e0e0"
  },
  divider : {
    backgroundColor:   "#283593",
    height : 4
  },
  middledivider : {
    height : 2
  }
}));

const Account = () => {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const { user, logout, image } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);
  

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      history.push('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar
          alt="User"
          className={classes.avatar}
          src={image}
          >
             {getInitials(`${user['custom:first_name']} ${user['custom:last_name']}`)}
          </Avatar>
        
        <Hidden smDown>
          <Typography
            variant="h6"
            color="inherit"
          >
            
            {getName(`${user['custom:first_name']}`)} {getName(`${user['custom:last_name']}`)} 
          </Typography>
        </Hidden>
      </Box>

      <Drawer
        anchor="right"
        classes={{ paper: classes.drawer }}
        ModalProps={{ BackdropProps: { invisible: true } }}
        onClose={handleClose}
        open={isOpen}
        variant="temporary"
      >
        
          <Box pt={3}     bgcolor="#e0e0e0" height = "auto">
          <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              
            >
              <Box flexGrow ={1} />
              <Tooltip title="Exit">
        <IconButton onClick ={handleClose}>
        <CancelOutlinedIcon />
      </IconButton>
      </Tooltip>
          </Box>
          <Box
          
          display="flex"
          alignItems="center"
          flexDirection="column"
          textAlign="center"
        >
          <Avatar
          alt="User"
          className={classes.avatar1}
          src={image}
          >
             {getInitials(`${user['custom:first_name']} ${user['custom:last_name']}`)}
          </Avatar>
          
          <Typography
            className={classes.name}
            color="textPrimary"
            
            variant="h3"
          >
            {getName(`${user['custom:first_name']}`)} {getName(`${user['custom:last_name']}`)} 
          </Typography>
         
          <Typography
           
            color="textPrimary"
            
            variant="body1"
          >
           {user.email}
          </Typography>
   
          <Typography
            
            color="textPrimary"
            
            variant="body1"
          >
            {user.Username}
          </Typography>
         
        </Box>
          </Box>
          <Box display ="flex" bgcolor="#e0e0e0" >
          <Box flexGrow = {1} />
          <Box mr = {2} mb ={1}>
          <Button color="secondary" onClick = {handleLogout}>SIGN OUT</Button>
          </Box>
          </Box>
          <Divider className = {classes.divider}/>
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <Box p={3}>
            
              <Typography
                variant="h3"
                color="textPrimary"
              >
                Subscriptions
              </Typography>            
            </Box>
            <Divider variant="middle" className= {classes.middledivider}/>
            
        </PerfectScrollbar>
      </Drawer>
   
    </>
  );
}

export default Account;
